import * as React from 'react';
import Footer from 'styleguide/components/Footer/Footer';
import Grid from 'styleguide/components/Grid/Grid';
import Span from 'styleguide/components/Span/Span';

const FourOhFour = () => (
  <>
    <Grid.Container>
      <Grid>
        <Grid.Row className="mx-0 mb-0 mt-12 md:my-48">
          <Grid.Col>
            <div className="flex rotate-[-5deg] justify-center">
              <Span className="whitespace-nowrap bg-blue px-[0.5em] py-0 text-3xl font-bold leading-[1.375em] tracking-tighter text-shades-0 md:!text-[70px]">
                404: PAGE NOT FOUND
              </Span>
            </div>
          </Grid.Col>
        </Grid.Row>
      </Grid>
    </Grid.Container>
    <Footer />
  </>
);

export default FourOhFour;
